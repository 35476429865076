<!--
 * @Author:
 * @Date: 2022-10-09 13:46:47
 * @LastEditors: ywl 15203284409@163.com
 * @LastEditTime: 2024-10-08 16:30:23
 * @Description:
-->
<template>
  <div id="app" style="overflow: hidden">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    console.info(
      "%c✨versionTime:2024-11-14 14:30",
      "color: #43bb88;font-size: 16px;"
    );

    // 阻止浏览器自动翻译
    document.documentElement.setAttribute("lang", "zh");

    //为解决页面刷新时vuex $store存储的tabpage数据丢失，在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>

<style>
@font-face {
  font-family: "Akrobat";
  src: url("@/assets/font/Akrobat/Akrobat-Bold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Alimama FangYuanTi VF";
  src: url("@/assets/font/AlimamaFangYuanTiVF/alimmf.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*  分页组件全局样式  */
.el-pagination {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

/*  搜索按钮全局样式  */
.el-button--query {
  color: #fff !important;
  font-size: 16px !important;
  background: #09b189 !important;
  border: none !important;
}
.el-button--query:hover {
  background: #059875 !important;
  border: none !important;
}
.el-button--query:active {
  background: #057359 !important;
  border: none !important;
}

/* table上方按钮全局样式 new */
.el-button--table_create_new {
  min-width: 88px;
  height: 36px;
  color: #fff !important;
  border-radius: 8px !important;
  background: #09b189 !important;
  border: 1px solid #09b189 !important;
}
/* table上方按钮全局样式 */
.el-button--table_create {
  line-height: normal !important;
  color: #09b189 !important;
  background: rgba(9, 177, 137, 0.05) !important;
  border: 1px solid #09b189 !important;
}
.el-button--table_create:hover {
  background: #059875 !important;
  border: 1px solid #059875 !important;
  color: #fff !important;
}
.el-button--table_create:active {
  background: #057359 !important;
  border: 1px solid #057359 !important;
  color: #fff !important;
}

.el-button--table_create1 {
  background: #09b189 !important;
  border: 1px solid #09b189 !important;
  color: #fff !important;
}
/* table上方按钮全局样式 - 删除 */
.el-button--table_delete {
  color: #f1404b !important;
  background: rgba(241, 64, 75, 0.05) !important;
  border: 1px solid #f1404b !important;
}
.el-button--table_delete:hover {
  background: rgba(241, 64, 75, 0.8) !important;
  border: 1px solid rgba(241, 64, 75, 0.8) !important;
  color: #fff !important;
}
.el-button--table_delete:active {
  background: #f1404b !important;
  border: 1px solid #f1404b !important;
  color: #fff !important;
}

/* 保存 */
.el-button--table_save {
  min-width: 92px;
  height: 32px;
  color: #fff !important;
  background: #09b189 !important;
  border: 1px solid #09b189 !important;
}
.el-button--table_save:hover {
  /* min-width: 92px;
  height: 32px; */
  background: #059875 !important;
  border: 1px solid #059875 !important;
  color: #fff !important;
}
.el-button--table_save:active {
  /* min-width: 92px;
  height: 32px; */
  background: #057359 !important;
  border: 1px solid#057359 !important;
  color: #fff !important;
}

/* 关闭 */
.el-button--button_cancel {
  min-width: 92px;
  height: 32px;
  color: #212121 !important;
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid #ededed !important;
}
/* 关闭 */
.el-button--button_cancel1 {
  /* min-width: 92px; */
  height: 32px;
  color: #212121 !important;
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid #ededed !important;
}

.el-button--button_cancel:hover {
  /* min-width: 92px;
  height: 32px; */
  background: #f8f8f8 !important;
  border: 1px solid #ededed !important;
  color: #212121 !important;
}
.el-button--button_cancel:active {
  /* min-width: 92px;
  height: 32px; */
  background: #ececec !important;
  border: 1px solid #ededed !important;
  color: #212121 !important;
}
</style>
