export function debounce(fn, delay = 500) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(fn.bind(this, ...args), delay);
  };
}

import emoji from "./emoji";

export default function directive(app) {
  app.directive("emoji", emoji);
}

export function loadBMap(ak) {
  return new Promise(function (resolve, reject) {
    if (typeof BMap !== "undefined") {
      resolve(BMap);
      return true;
    }
    window.onBMapCallback = function () {
      resolve(BMap);
    };
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://api.map.baidu.com/api?v=3.0&ak=" +
      ak +
      "&__ec_v__=20190126&callback=onBMapCallback";
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export function getCurrentDate(type) {
  let t = type ? type : ".";
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  return `${year}${t}${month}${t}${day}`;
}
export function getCurrentDateTime() {
  const now = new Date();
  const weekdays = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  const dayOfWeek = weekdays[now.getDay()];

  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const h = now.getHours().toString().padStart(2, "0");
  const m = now.getMinutes().toString().padStart(2, "0");
  const s = now.getSeconds().toString().padStart(2, "0");

  return `${now.getFullYear()}年${month}月${day}日 ${dayOfWeek} ${h}:${m}:${s}`;
}

//#通过计算将百度经纬度转换为高德经纬度def
//g =lng   // t =lat
export function bMapTransQQMap(lng, lat) {
  let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
  let x = lng - 0.0065;
  let y = lat - 0.006;
  let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
  let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
  let lngs = z * Math.cos(theta);
  let lats = z * Math.sin(theta);
  console.log("转换后", {
    lng: lngs,
    lat: lats,
  });
  return {
    lng: lngs,
    lat: lats,
  };
}
// 深拷贝对象
export function deepClone(obj) {
  const _toString = Object.prototype.toString;

  // null, undefined, non-object, function
  if (!obj || typeof obj !== "object") {
    return obj;
  }

  // DOM Node
  if (obj.nodeType && "cloneNode" in obj) {
    return obj.cloneNode(true);
  }

  // Date
  if (_toString.call(obj) === "[object Date]") {
    return new Date(obj.getTime());
  }

  // RegExp
  if (_toString.call(obj) === "[object RegExp]") {
    const flags = [];
    if (obj.global) {
      flags.push("g");
    }
    if (obj.multiline) {
      flags.push("m");
    }
    if (obj.ignoreCase) {
      flags.push("i");
    }

    return new RegExp(obj.source, flags.join(""));
  }

  const result = Array.isArray(obj)
    ? []
    : obj.constructor
    ? new obj.constructor()
    : {};

  for (const key in obj) {
    result[key] = deepClone(obj[key]);
  }

  return result;
}

export function makeMap(str, expectsLowerCase) {
  const map = Object.create(null);
  const list = str.split(",");
  for (let i = 0; i < list.length; i++) {
    map[list[i]] = true;
  }
  return expectsLowerCase ? (val) => map[val.toLowerCase()] : (val) => map[val];
}

//递归列表组成树形结构
export function buildTree(data) {
  let cloneData = JSON.parse(JSON.stringify(data)); // 对源数据深度克隆

  let tree = cloneData.filter((father) => {
    //循环所有项

    let branchArr = cloneData.filter((child) => {
      return father.id == child.pId; //返回每一项的子级数组
    });

    if (branchArr.length > 0) {
      father.children = branchArr; //如果存在子级，则给父级添加一个children属性，并赋值
    }

    return father.pId == 0; //返回第一层
  });

  return tree; //返回树形数据
}

//获取往前推多少天的日期
export function getDateByType(days, type) {
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - days);

  function formatDate(date, type, days) {
    // 当 days 为 1 时，单独处理不同类型
    if (days == "current") {
      switch (type) {
        case "year":
          return date.toISOString().split("T")[0]; // 返回年份
        case "month":
          return (
            date.getFullYear() +
            "" +
            (date.getMonth() + 1).toString().padStart(2, "0")
          ); // 返回年月格式 yyyyMM
        case "day":
          return date.toISOString().split("T")[0]; // 返回日期格式 yyyy-MM-dd
        case "hour":
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const hour = date.getHours().toString().padStart(2, "0");
          return `${year}-${month}-${day} ${hour}:00`; // 返回日期和时间格式 yyyy-MM-dd HH:00:00
        default:
          return null;
      }
    }

    // 如果不是 1 天，按原来的逻辑处理
    switch (type) {
      case "year":
        return date.getFullYear(); // 返回年份
      case "month":
        return (
          date.getFullYear() +
          "" +
          (date.getMonth() + 1).toString().padStart(2, "0")
        ); // 返回年月格式 yyyyMM
      case "day":
        return date.toISOString().split("T")[0]; // 返回日期格式 yyyy-MM-dd
      case "hour":
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hour = date.getHours().toString().padStart(2, "0");
        return `${year}-${month}-${day} ${hour}:00`; // 返回日期和时间格式 yyyy-MM-dd HH:00:00
      default:
        return null;
    }
  }

  return {
    start: formatDate(days == "current" ? currentDate : startDate, type, days), // 返回开始时间
    end: formatDate(currentDate, type, days), // 返回结束时间（当前时间）
  };
}

export function getDateByType2(days, type) {
  const currentDate = new Date();
  const startDate = new Date(currentDate);

  function formatDate(date, type) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");

    switch (type) {
      case "year":
        return `${year}`; // 返回年份
      case "month":
        return `${year}-${month}`; // 返回年月格式 yyyy-MM
      case "day":
        return `${year}-${month}-${day}`; // 返回日期格式 yyyy-MM-dd
      case "hour":
        return `${year}-${month}-${day} ${hour}:00`; // 返回日期和时间格式 yyyy-MM-dd HH:00
      default:
        return null;
    }
  }

  if (days == "current") {
    // 处理 current 逻辑
    switch (type) {
      case "hour":
        // start 从当天的 0 点开始，end 为当前时间
        startDate.setHours(0, 0, 0, 0);
        return {
          start: formatDate(startDate, type),
          end: formatDate(currentDate, type),
        };
      case "day":
        // 默认前 7 天到当前
        startDate.setDate(currentDate.getDate() - 7);
        console.log(startDate);
        return {
          start: formatDate(startDate, type),
          end: formatDate(currentDate, type),
        };
      case "month":
        // 当月范围
        startDate.setDate(1); // 当月第一天
        startDate.setHours(0, 0, 0, 0);
        return {
          start: formatDate(startDate, type),
          end: formatDate(currentDate, type),
        };
      case "year":
        // 当年范围
        startDate.setMonth(0, 1); // 当年第一天
        startDate.setHours(0, 0, 0, 0);
        return {
          start: formatDate(startDate, type),
          end: formatDate(currentDate, type),
        };
      default:
        return null;
    }
  }

  // 非 current 逻辑
  startDate.setDate(currentDate.getDate() - days);
  return {
    start: formatDate(startDate, type),
    end: formatDate(currentDate, type),
  };
}

//上周日期
export function getLastWeekRange() {
  const today = new Date(); // 获取当前日期
  const dayOfWeek = today.getDay(); // 获取当前是星期几（0-6，0 是周日）
  const startOfLastWeek = new Date(today); // 复制当前日期
  const endOfLastWeek = new Date(today); // 复制当前日期

  // 计算上周的开始日期（上周一）
  startOfLastWeek.setDate(today.getDate() - dayOfWeek - 6);
  // 计算上周的结束日期（上周日）
  endOfLastWeek.setDate(today.getDate() - dayOfWeek);

  // 格式化日期为 YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份补零
    const day = String(date.getDate()).padStart(2, "0"); // 日期补零
    return `${year}-${month}-${day}`;
  };

  return {
    start: formatDate(startOfLastWeek),
    end: formatDate(endOfLastWeek),
  };
}

//本周
export function getThisWeekRange() {
  const today = new Date(); // 获取当前日期
  const dayOfWeek = today.getDay(); // 获取当前是星期几（0-6，0 是周日）
  const startOfWeek = new Date(today); // 复制当前日期

  // 计算本周的开始日期（本周一）
  startOfWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

  // 格式化日期为 YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份补零
    const day = String(date.getDate()).padStart(2, "0"); // 日期补零
    return `${year}-${month}-${day}`;
  };

  return {
    start: formatDate(startOfWeek), // 本周一的日期
    end: formatDate(today), // 当前日期
  };
}

//本月
export function getThisMonthRange() {
  const today = new Date(); // 获取当前日期
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // 获取本月的第一天
  const endOfMonth = today; // 结束日期是今天

  // 格式化日期为 YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份补零
    const day = String(date.getDate()).padStart(2, "0"); // 日期补零
    return `${year}-${month}-${day}`;
  };

  return {
    start: formatDate(startOfMonth), // 本月的第一天
    end: formatDate(endOfMonth), // 今天作为本月的最后一天
  };
}
