/*
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:21:25
 * @LastEditors: ywl 15203284409@163.com
 * @LastEditTime: 2024-10-08 16:19:15
 */
import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import axiosConfig from "./api/axios.config";
import "./assets/css/reset.css"; // 清除css默认冗余样式
import "./assets/css/table.less";
//  引入 element
import "element-plus/dist/index.css";

import ElementPlus from "element-plus";
import zh_ch from "element-plus/dist/locale/zh-cn";
import "./styles/element-variables.less";
import VueGridLayout from "vue-grid-layout"; // 引入layout

import "dayjs/locale/zh-cn";
import axios from "axios";
import store from "./store";
// import './plugins/element.js'
import http from "./commons/utils/ajax";
import "./commons/css/common.css";
import commonTable from "@/components/table/commonTable";
import EditForm from "@/components/EditForm/EditForm";
import print from "vue3-print-nb";
import { ElMessage } from "element-plus";
import * as ELIcons from "@element-plus/icons";

import moment from "moment";
import "./assets/iconfont/iconfont.js";

import Print from "vue-print-nb";

const app = createApp(App);
app.use(Print);
app.use(VueGridLayout);
// import VueHighlightJS from "vue3-highlightjs";
// app.use(VueHighlightJS);
import directive from "./utils/index.js"; // directive
directive(app);
// Vue.directive('emoji', emoji)
for (let iconName in ELIcons) app.component(iconName, ELIcons[iconName]);
app.config.globalProperties.$moment = moment;
app.use(ElementPlus, { locale: zh_ch });
app._context.components.ElDialog.props.closeOnClickModal.default = false; // 全局关闭点遮罩关闭弹框
app._context.components.ElDialog.props.closeOnPressEscape.default = false; // 全局关闭点遮罩关闭弹框
import frontUtils from "./utils/frontJavaScriptUtils";
app.config.globalProperties.$frontUtils = frontUtils;
import $utils from "./utils/utils";
app.config.globalProperties.$utils = $utils;
app.use(router);
app.use(store);
axiosConfig(router);
app.use(print);
app.config.globalProperties.productionTip = false;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.BASEPATH = process.env.VUE_APP_API_BASEURL; //后台代理访问前缀
window.BASEPATH = process.env.VUE_APP_API_BASEURL;
app.config.globalProperties.PRODUCT_PATH = process.env.VUE_APP_PRODUCT_PATH; //前端页面访问相对路径
app.provide("$message", ElMessage);
window.PRODUCT_PATH = process.env.VUE_APP_PRODUCT_PATH;
app.config.globalProperties.http = http;
app.config.globalProperties.USERROLE = new Set();
app.config.globalProperties.currentTabName = "";
app.config.globalProperties.productionTip = true;
app.config.globalProperties.devtools = "";
app.component("commonTable", commonTable);
app.component("EditForm", EditForm);

const crypto = require("./utils/houdu-crypto-utils");
app.config.globalProperties.$crypto = crypto;

// const getInput = (el) => (el ? el : el.querySelector("input"));
// let inputHandler = () => {};
// app.directive("emojiSymbol", {
//   mounted(el, { arg, value }) {
//     const input = getInput(el).getElementsByTagName("input")[0];
//     if (input) {
//       var regExp = /[^\u4e00-\u9fa5a-zA-Z0-9]/gi;
//       // /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|[^\u4e00-\u9fa5a-zA-Z0-9]|\uAE|\u3030/gi;
//       inputHandler = () => {
//         // 替换所有的非数字项
//         // 如果输入的数字不符合正则表达式，则替换为''
//         input.value = input.value.replace(regExp, "");
//         console.log(input.value);
//       };
//       // 在文本框输入的时候触发
//       input.addEventListener("input", inputHandler, true);
//     }
//   },
//   unmounted(el) {
//     // 解除绑定的时候去除事件
//     const input = getInput(el);
//     input.removeEventListener("input", inputHandler, true);
//   },
// });

app.directive("blurs", {
  mounted(el, { arg, value }) {
    el.addEventListener("click", () => {
      el.blur();
    });
  },
  unmounted(el) {},
});

// 注册全局组件  -- dialog
import modelDialog from "./components/modelDialog.vue";
app.component("Modeldialog", modelDialog);
import modelHintDialog from "./components/modelHintDialog.vue";
app.component("ModelHintdialog", modelHintDialog);
import function1 from "./components/function/functionCheck.vue";
app.component("FunctionCheck", function1);
import function2 from "./components/function/functionCheckMini.vue";
app.component("FunctionCheckMini", function2);
import modelNoneDialog from "./components/modelNoneDialog.vue";
app.component("ModelNoneDialog", modelNoneDialog);
import Exportfilebutton from "./components/exportFileButton.vue";
app.component("Exportfilebutton", Exportfilebutton);
// import searchButton from "./components/searchButton.vue";
// app.component("Searchbutton", searchButton);
import errDialog from "./components/uploadFileErrorDialog.vue";
app.component("Errdialog", errDialog);
import loading from "./components/modelLoading.vue";
app.component("Loading", loading);

import myButton from "./components/HdButton/index.vue";
app.component("myButton", myButton);

import Templateupload from "./components/uploadFileTemplate.vue";
app.component("Templateupload", Templateupload);

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};

import "./assets/css/public.less"; // 引入全局样式
app.mixin({
  mounted() {
    if (this.$el.dataset) {
      let url = this.$el.dataset.url;
      if (
        !!url &&
        (this.$options.name == "ElButton" || this.$options.name == "HdButton")
      ) {
        let inter = setInterval(() => {
          if (this.USERROLE && this.USERROLE.size) {
            if (!this.USERROLE.has(url)) {
              this.$el.style.display = "none";
              clearInterval(inter);
            }
            clearInterval(inter);
          }
        }, 10);
      }
    }
  },
});

// import Autocomplete from "./components/Autocomplete.vue"
// app.component("Autocomplete", Autocomplete);

app.mount("#app");
